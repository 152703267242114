import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable, { TableStyles } from "react-data-table-component";
//selectors
import { getAllEnquiryList } from "../../Application/Selectors/enquiry";
import { getLoading } from "../../Application/Selectors/loader";
//actions
import { loadEnquiryList } from "../../Application/Actions/loader";
//DTO
import { enquiryListDTO } from "../../Infrastructure/Core/DTOs/Enquiry/EnquiryListDTO";
import ShimmerTable from "../Loader/Table";
import CommonFields from "../../Infrastructure/Core/Helper/CommonFields";

function EnquiryManage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const enquiryList: enquiryListDTO[] = useSelector(getAllEnquiryList);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;

  const [searchText, setSearchText] = useState("");

  let commonFields: Array<any> | null = JSON.parse(
    localStorage.getItem("commonField") || "null"
  );
  const tableColsName = [
    "Name",
    "Brand Name",
    "Email",
    "Contact Number",
    "Country",
    "Status",
    "Sales Rep",
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "35px",
      },
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row: enquiryListDTO) => row.name,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div
          className="px-5 py-4 font-medium text-gray-900 truncate cursor-pointer"
          onClick={() => handleProceed(row.id)}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Brand Name",
      selector: (row: enquiryListDTO) => row.brandName,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div
          className="px-5 py-4 truncate"
          onClick={() => handleProceed(row.id)}
        >
          {row.brandName}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row: enquiryListDTO) => row.email,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-5 py-4 truncate">{row.email}</div>
      ),
    },
    {
      name: "Contact Number",
      selector: (row: enquiryListDTO) => row.phone,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-6 py-4 truncate">{row.phone}</div>
      ),
    },
    {
      name: "Country",
      selector: (row: enquiryListDTO) => row.country,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-6 py-4 truncate">{row.country}</div>
      ),
    },
    {
      name: "Status",
      selector: (row: enquiryListDTO) => row.saleStatus,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <span
          className={`text-center block w-[150px] truncate px-2 py-1 md:px-6 md:py-1 rounded-full font-medium ${
            row.saleStatus === CommonFields.saleStatus.salesDone
              ? "px-12 text-green-600 bg-green-100"
              : row.saleStatus === CommonFields.saleStatus.noSales
              ? "px-12 text-red-600 bg-red-100"
              : row.saleStatus === CommonFields.saleStatus.ongoingSales
              ? "px-6 text-blue-600 bg-blue-100"
              : "px-12 text-orange-600 bg-orange-100"
          }`}
        >
          {commonFields?.find((cmf) => cmf.id === row.saleStatus)?.fieldValue}
        </span>
      ),
    },
    {
      name: "Sales Rep",
      selector: (row: enquiryListDTO) => row.salesRepName,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <span
          className={`text-center block w-[150px] truncate px-6 py-1 rounded-full font-medium ${
            row.salesRepresentative === ""
              ? "text-orange-600 bg-orange-100"
              : "text-slate-600 bg-gray-200"
          }`}
        >
          {row.salesRepresentative === "" ? "Unassigned" : row.salesRepName}
        </span>
      ),
    },
  ];
  const analyticalData = [
    {
      analyticsName: "Total Leads",
      borderColor: "border-fuchsia-600",
      analyticsCount: enquiryList?.length ? enquiryList?.length : 0,
      src: "fa-solid fa-headset text-[#92278f] bg-[#ffc7fd] text-3xl",
    },
    {
      analyticsName: "Unassigned",
      borderColor: "border-orange-500",
      analyticsCount: enquiryList?.filter(
        (enquiry) => enquiry?.salesRepresentative === ""
      )?.length,
      src: "fas fa-user-slash text-[#ffa500] bg-[#ffefd1] text-2xl",
    },
    {
      analyticsName: "Ongoing",
      borderColor: "border-blue-500",
      analyticsCount: enquiryList?.filter(
        (enquiry) =>
          enquiry?.saleStatus === CommonFields.saleStatus.ongoingSales
      )?.length,
      src: "fa-solid fa-user-check text-[#0096ff] bg-[#ebf7ff] text-2xl",
    },
    {
      analyticsName: "Sale Closed",
      borderColor: "border-green-500",
      analyticsCount: enquiryList?.filter(
        (enquiry) => enquiry?.saleStatus === CommonFields.saleStatus.salesDone
      )?.length,
      src: "fa-solid fa-handshake text-[#228b22] bg-[#dbffdb] text-2xl",
    },
    {
      analyticsName: "Sale Missed",
      borderColor: "border-red-500",
      analyticsCount: enquiryList?.filter(
        (enquiry) => enquiry?.saleStatus === CommonFields.saleStatus.noSales
      )?.length,
      // src: "nosale",
      src: "fa-solid fa-handshake-simple-slash text-[#ff0000] bg-[#ffe5e5] text-2xl",
    },
  ];

  useEffect(() => {
    dispatch(loadEnquiryList);
  }, [dispatch]);

  const handleProceed = (e: string | undefined) => {
    navigate(`/EnquiryDetails/${e}`);
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const filteredData = enquiryList.filter((item) => {
    const statusValue = commonFields?.find(
      (cmf) => cmf.id === item.saleStatus
    )?.fieldValue;
    const salesRepValue = item.salesRepName || "Unassigned";
    return (
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.brandName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase()) ||
      item.phone.toLowerCase().includes(searchText.toLowerCase()) ||
      item.country.toLowerCase().includes(searchText.toLowerCase()) ||
      (statusValue &&
        statusValue.toLowerCase().includes(searchText.toLowerCase())) ||
      (salesRepValue &&
        salesRepValue.toLowerCase().includes(searchText.toLowerCase()))
    );
  });

  return (
    <>
      <h3 className="text-slate-700 font-semibold text-lg md:text-xl">
        Enquiry Management
      </h3>
      <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 my-5 gap-6">
        {analyticalData.map((value: any, index: any) => (
          <div
            key={index}
            className={`bg-white p-2 md:p-3 h-16 md:h-20 rounded-xl shadow-lg border-b-4 ${value.borderColor}`}
          >
            <div className="flex items-center">
              <div className="px-3 py-1">
                <i
                  className={`${value.src} text-2xl px-3 py-2 rounded-full`}
                ></i>
              </div>
              <div className="flex flex-col px-3 py-1">
                <div>
                  <h4 className="font-medium text-[10px] md:text-[13px] xl:text-[12px]">
                    {value.analyticsName}
                  </h4>
                </div>
                <div>
                  {loading && value === "getEnquiryList" ? (
                    <div className="animate-pulse font-semibold text-[14px] md:text-[18px] bg-gray-200 w-8 h-5 mt-2"></div>
                  ) : (
                    <h4 className="font-semibold text-[14px] md:text-[18px]">
                      {value.analyticsCount}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end my-3">
        <input
          type="text"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search..."
          className="border-[1px] border-slate-300 px-2 py-2 rounded text-sm shadow focus:outline-none focus:ring-1 md:w-2/5 lg:w-2/5 xl:w-1/5 2xl:w-1/5 ease-linear transition-all duration-150 mx-5"
        />
          <button
          className="bg-red-600 text-white active:bg-red-700 hover:bg-red-500 font-medium uppercase text-xs lg:text-base px-3 py-1 lg:px-10 lg:py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
          // onClick={()=>handleSalesFormSubmit()}
        >
          Add Lead
        </button>
      </div>
      <div className="overflow-x-auto shadow-lg rounded-lg">
        {loading && value === "getEnquiryList" ? (
          <ShimmerTable
            colNames={tableColsName}
            colsCount={tableColsName.length}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}
export default EnquiryManage;
