import React from "react";
import { Route, Routes } from "react-router-dom";
import EnquiryManage from "../Views/Enquiry/EnquiryManage";
import Login from "../Views/Account/Login";
import ProtectedRoute from "../Infrastructure/Core/Helper/ProtectedRoute";
import Dashboard from "../Views/Dashboard/Dashboard";
import EnquiryDetails from "../Views/Enquiry/Details/EnquiryDetails";
import CustomerManage from "../Views/Customer/CustomerManage";
import CustomerDetails from "../Views/Customer/Details/CustomerDetails";
import SalesRepManage from "../Views/SalesRep/SalesRepManage";
// import SalesRepAdd from "../Views/Plan/PlanCU";
import PlanManage from "../Views/Plan/PlanManage";
import PlanCU from "../Views/Plan/PlanCU";
import EnquiryC from "../Views/Enquiry/EnquiryC";
function AppRouting() {
  return (
    <>
      <Routes>
      <Route
          path="/Login"
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/"
          element={
            <ProtectedRoute accessBy="authenticated">
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/Dashboard"
          element={
            <ProtectedRoute accessBy="authenticated">
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/EnquiryManage"
          element={
            <ProtectedRoute accessBy="authenticated">
              <EnquiryManage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/EnquiryCreate"
          element={
            <ProtectedRoute accessBy="authenticated">
              <EnquiryC />
            </ProtectedRoute>
          }
        ></Route>
         <Route
          path="/EnquiryDetails/:id"
          element={
            <ProtectedRoute accessBy="authenticated">
              <EnquiryDetails />
            </ProtectedRoute>
          }
        ></Route> 
        <Route
          path="/CustomerManage"
          element={
            <ProtectedRoute accessBy="authenticated">
              <CustomerManage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/CustomerDetail/:id"
          element={
            <ProtectedRoute accessBy="authenticated">
              <CustomerDetails />
            </ProtectedRoute>
          }
        ></Route> 
        <Route
          path="/SalesRepManage"
          element={
            <ProtectedRoute accessBy="authenticated">
              <SalesRepManage />
            </ProtectedRoute>
          }
        ></Route> 
        <Route
          path="/PlanCreate"
          element={
            <ProtectedRoute accessBy="authenticated">
              <PlanCU />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/PlanManage"
          element={
            <ProtectedRoute accessBy="authenticated">
              <PlanManage />
            </ProtectedRoute>
          }
        ></Route> 
        <Route
          path="/PlanDetail/:id"
          element={
            <ProtectedRoute accessBy="authenticated">
              <PlanCU />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </>
  );
}
export default AppRouting;
